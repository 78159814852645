import {setToken, removeToken, saveLoginInfo, getLoginInfo, clearLoginInfo} from '@/utils'
import {_login, _wx_official_login,  _logout, _user_info} from '@/service/api'
import {request} from '@/service/utils'
export default {
    state :{
        userInfo: getLoginInfo(),
    },
    mutations :{
        up_user_info(state, info){
            state.userInfo = info
        },
        set_user_info(state, info){
            state.userInfo = info
            saveLoginInfo(info)
        },
        clear_login_info(state){
            state.userInfo = {
                avatar: '',
                nickname: '',
                username: '',
            }
            clearLoginInfo()
            removeToken()
        },
    },
    actions :{
        login({dispatch}, loginParams) {
            return new Promise((resolve, reject) => {
                // console.log('axios.post', loginParams)
                request(_login, loginParams).then(res => {
                    setToken(res.data.token)
                    dispatch('getUserInfo').then((userInfo) => {
                        resolve(userInfo)
                    })
                }).catch(error => {
                    reject(error)
                })
            })
        },

        officialLogin({dispatch}, loginParams) {
            return new Promise((resolve, reject) => {
                // console.log('axios.post', loginParams)
                request(_wx_official_login, loginParams).then(res => {
                    setToken(res.data.token)
                    dispatch('getUserInfo').then((userInfo) => {
                        resolve(userInfo)
                    })
                }).catch(error => {
                    reject(error)
                })
            })
        },

        getUserInfo({commit}){
            return new Promise((resolve, reject) => {
                request(_user_info, {}).then(res => {
                    console.log('getUserInfo', res)
                    commit('set_user_info', res.data)
                    resolve(res.data)
                }).catch(error => {
                    commit('clear_login_info',{})
                    reject(error)
                })
            })
        },

        logout({commit}){
            return new Promise((resolve, reject) => {
                request(_logout, {}).then(res => {
                    console.log(res)
                    commit('clear_login_info', {})
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
