import {getToken} from '@/utils'
const LoginAuthPlugin = {
    install(Vue) {
        Vue.mixin({
            beforeCreate() {
                if (this.$options.requireLoginMethods) {
                    const requireLoginMethods = this.$options.requireLoginMethods
                    //console.log('requireLoginMethods', requireLoginMethods)
                    requireLoginMethods.forEach(key => {
                        if (this.$options.methods[key]) {
                            const method = this.$options.methods[key]
                            // console.log(method)
                            this.$options.methods[key] = function () {
                                // console.log('arguments', arguments)
                                if (this.$isLogin()) {
                                    return method.apply(this, arguments)
                                } else {
                                    console.log('请先登陆')
                                    this.$handleLogin()
                                }
                            }
                        }
                    })
                }
            },
            methods: {
                /**
                 * 是否登陆
                 */
                $isLogin() {
                    if (getToken()) {
                        return true
                    }
                    return false
                },
                /**
                 * 去登陆
                 */
                $handleLogin() {
                    this.$router.push({
                        name: 'login',
                        query: {
                            redirect: encodeURIComponent(this.$route.fullPath)
                        }
                    })
                },
            }
        })
    }
}

export default LoginAuthPlugin
