<template>
  <div id="app">
    <!--<transition name="fade">-->
        <keep-alive :include="keeps">
          <router-view></router-view>
        </keep-alive>
     <!--</transition>-->
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      keeps: ['Index', 'Search'],
      ti: null
    }
  },
  methods: {

  },
  mounted() {
      const that = this

      // ---
      if(that.$isLogin()) {
          that.$store.dispatch('getUnread', {}).then(() => {
          }).catch(() => {
          })
      }
      // ---
      that.ti = setInterval(() => {
          if(that.$isLogin()) {
              that.$store.dispatch('getUnread', {}).then(() => {
              }).catch(() => {
              })
          }
      }, 15000)
  },
  beforeDestroy() {
    if(this.ti != null) {
        clearInterval(this.ti)
        this.ti = null
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  width: 100%;
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
}
</style>
