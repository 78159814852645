import {baseConfig} from '@/config'
export default {
    state :{
        baseConfig: baseConfig,
    },
    mutations :{
        set_base_config: (state, baseConfig) => {
            state.baseConfig = baseConfig
        },
    },
    actions :{
        setBaseConfig({ commit }, baseConfig) {
            commit('set_base_config', baseConfig)
        }
    }
}