export default {
    state :{
        pale: [0, 0, 0, 0]
    },
    mutations :{
        set_pale(state, pale){
            state.pale = pale
        }
    },
    actions :{
    }
}
