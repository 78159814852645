const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', auth: false}
    },
    {
        path: '/area',
        name: 'area',
        component: () => import('@/views/area/Index'),
        meta: { title: '城市', auth: false}
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('@/views/search/Index'),
        meta: { title: '搜索', auth: false}
    },
    {
        path: '/trend',
        name: 'trend',
        component: () => import('@/views/trends/Trend'),
        meta: { title: '动态', auth: false}
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/Index'),
        meta: { title: '个人主页', auth: false}
    },
    {
        path: '/me',
        name: 'me',
        component: () => import('@/views/me/Index'),
        meta: { title: '我的', auth: false}
    },
    {
        path: '/follows',
        name: 'follows',
        component: () => import('@/views/follow/Follows'),
        meta: { title: '关注', auth: false}
    },
    {
        path: '/fans',
        name: 'fans',
        component: () => import('@/views/follow/Fans'),
        meta: { title: '粉丝', auth: false}
    },
    {
        path: '/message',
        name: 'message',
        component: () => import('@/views/message/Index'),
        meta: { title: '消息', auth: true}
    },
    {
        path: '/inbox',
        name: 'inbox',
        component: () => import('@/views/message/Inbox'),
        meta: { title: '私信', auth: true}
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('@/views/message/Chat'),
        meta: { title: '私信', auth: true}
    },
    {
        path: '/setting',
        name: 'setting',
        component: () => import('@/views/setting/Index'),
        meta: { title: '设置', auth: true}
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/setting/Profile'),
        meta: { title: '编辑资料', auth: true}
    },
    {
        path: '/cropper',
        name: 'cropper',
        component: () => import('@/views/setting/Cropper'),
        meta: { title: '设置头像', auth: true}
    },
    {
        path: '/password',
        name: 'password',
        component: () => import('@/views/setting/Password'),
        meta: { title: '设置密码', auth: true}
    },
    {
        path: '/report',
        name: 'report',
        component: () => import('@/views/report/List'),
        meta: { title: '举报记录', auth: true}
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: () => import('@/views/anyone/Agreement'),
        meta: { title: '用户协议', auth: false}
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/anyone/Privacy'),
        meta: { title: '隐私政策', auth: false}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/Index'),
        meta: { title: '登陆' }
    },
    {
        path: '/showmap',
        name: 'showmap',
        component: () => import('@/views/showmap/Index'),
        meta: { title: '', auth: false}
    },
    {
        path: '/adh5',
        name: 'adh5',
        component: () => import('@/views/adh5/Index'),
        meta: { title: '', auth: false}
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/error/NotFound'),
        meta: { title: '404' }
    },
    {
        path: '*',
        redirect: { name: '404' }
    }
];
export default routes
