const assetsPath = 'http://hc-serv.kexunxx.com/uploads/assets'
const baseConfig = {
  appName: '话城',
  appLogo: require('@/assets/images/logo.png'),
  defAvatar: require('@/assets/images/avatar.png'),
  copyright: '©柯讯科技',
  defaultMapCenter: [116.3683244, 39.915085],
  emotes: {
    '[爱]': assetsPath + '/emote/ai.png',
    '[拜托]': assetsPath + '/emote/baituo.png',
    '[害羞]': assetsPath + '/emote/haixiu.png',
    '[好奇]': assetsPath + '/emote/haoqi.png',
    '[耶]': assetsPath + '/emote/ye.png',
    '[酷]': assetsPath + '/emote/ku.png',
    '[捂脸]': assetsPath + '/emote/wulian.png',
    '[捂嘴]': assetsPath + '/emote/wuzui.png',
    '[笑哭]': assetsPath + '/emote/xiaoku.png',
    '[红心]': assetsPath + '/emote/hongxin.png',
    '[赞]': assetsPath + '/emote/zan.png',
  },
  GIFs: [
    assetsPath + '/gif/huanhu.gif',
    assetsPath + '/gif/jizhi.gif',
  ],
  reportTypes: {
    '00': {title: '内容质量差'},
    '01': {title: '虚假不实消息'},
    '02': {title: '过度营销内容'},
    '03': {title: '引导不良价值观'},
    '04': {title: '侵犯他人权益', required: 'desc'},
  },
}

module.exports = {
  baseConfig
}
