import {_area_location} from '@/service/api'
import {request} from '@/service/utils'
export default {
    state :{
        location:{
            provinceId: 0,
            cityId: 0,
            districtId: 0,
            lon: 0,
            lat: 0,
            provinceName: '',
            cityName: '',
            districtName: '',
            areaName: '',
        },
        coordinate: null
    },
    mutations :{
        set_location(state, location){
            state.location = location
        },
        set_coordinate(state, coordinate){
            state.coordinate = coordinate
        },
    },
    actions :{
        getLocation({commit}, params){
            return new Promise((resolve, reject) => {
                request(_area_location, params).then(res => {
                    // console.log('getLocation', res)
                    commit('set_location', res.data)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
