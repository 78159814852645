import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import {getToken} from '@/utils'
import store from '@/store'

// push
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
// replace
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace (to) {
    return VueRouterReplace.call(this, to).catch(err => err)
}

Vue.use(Router)

const router = new Router({
    routes: routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    // console.log(from, to)
    document.title = store.getters.baseConfig.appName ? store.getters.baseConfig.appName : ''
    /*if (to.meta.title) {
        document.title = (store.getters.baseConfig.appName ? store.getters.baseConfig.appName + ' - ' : '') + to.meta.title
    }else {
        document.title = store.getters.baseConfig.appName ? store.getters.baseConfig.appName : ''
    }*/
    if (getToken()) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        if (!to.meta.auth) {
            next()
        } else {
            next({
                name: 'login',
                query: {
                    redirect: encodeURIComponent(to.fullPath)
                }
            })
        }
    }
    //next()
})

router.afterEach(() => {
})
export default router
