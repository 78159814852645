const dayjs = require('dayjs')

function setToken(token) {
    localStorage.setItem('token', token)
}

function getToken() {
    return localStorage.getItem('token')
}

function removeToken() {
    localStorage.removeItem('token')
}

function saveLoginInfo(userInfo) {
    localStorage.setItem('loginInfo', JSON.stringify(userInfo))
}

function getLoginInfo() {
    const loginInfo = localStorage.getItem('loginInfo')
    if(loginInfo) {
        return JSON.parse(loginInfo)
    }
    return {
        avatar: '',
        nickname: '',
        username: '',
    }
}

function clearLoginInfo() {
    localStorage.removeItem('loginInfo')
}

/**
 * 解析 url 中的参数
 * @param url
 * @returns {Object}
 */
function parseUrlParams(url) {
    const params = {}
    if (!url || url === '' || typeof url !== 'string') {
        return params
    }
    const paramsStr = url.split('?')[1]
    if (!paramsStr) {
        return params
    }
    const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
    for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] = value === 'true' ? true : (value === 'false' ? false : value)
    }
    return params
}

function friendlyFormatTime(dateTime) {
    const dj = dayjs(dateTime, 'YYYY-MM-DD HH:mm:ss')
    let minute = 1000 * 60
    let hour = minute * 60
    let day = hour * 24
    let week = day * 7
    let time1 = new Date().getTime()
    let time2 = dj.valueOf()
    let time = time1 - time2

    if (time / minute < 1) {
        return "刚刚"
    }else if (time / hour < 1) {
        return Math.floor(time / minute) + "分钟前"
    }else if (time / day < 1) {
        return Math.floor(time / hour) + "小时前"
    }else if (time / week < 1) {
        return Math.floor(time / day) + "天前"
    }else {
        return dj.format('YY年MM月DD日 HH:mm')
    }
}

function escapeHtml(str) {
    return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

function replaceAll(str, find, replace) {
    return str.split(find).join(replace);
}

module.exports = {
    setToken,
    getToken,
    removeToken,
    saveLoginInfo,
    getLoginInfo,
    clearLoginInfo,
    parseUrlParams,
    friendlyFormatTime,
    escapeHtml,
    replaceAll,
}
