import Vue from 'vue'

import router from './router'
import store from './store'
import Vant from 'vant'
import { Lazyload } from 'vant';
import './assets/scss/app.scss'
import 'vant/lib/index.css'
import "ol/ol.css"
import './icons'
import App from './App.vue'

import axios from './service/axios'
import apis from './service/api'
import Plugins from './plugins'

document.OLZ = 0

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$apis = apis
Vue.use(Plugins)
Vue.use(Vant);
Vue.use(Lazyload);
new Vue({
  router,
  store,
  render: h => h(App),
  created () {
  },
  beforeCreate(){
    Vue.prototype.$bus = this   //配置全局总线，bus有总线的意思
    document.addEventListener('UniAppJSBridgeReady', function() {
      // eslint-disable-next-line
      Vue.prototype.$uni= uni
    })
  },
  mounted () {
  }
}).$mount('#app')
